<template>
<div class="privacypolicy-container" :class="{'full-page': $route.meta.fullPage}">
    <div class="top">
        <div class="header">
            <h2 style="margin: 0;">Privacy Policy</h2>
            <p style="margin: 0;">Privacy Privacy ai sensi Regolamento UE 2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016 (Regolamento generale sulla protezione dei dati personali - GDPR) e del D. Lgs n. 196/2003 (Codice in materia di dati personali).</p>
        </div>

    </div>
    <p>
        La presente Privacy Policy ha lo scopo di descrivere le modalità di gestione di questo Sito, in riferimento all'uso dei cookie ed al trattamento dei dati personali degli utenti/visitatori che lo consultano.
    </p>
    <p>
        Si tratta di un'informativa resa, ai sensi del Regolamento 2016/679/UE (Regolamento generale sulla protezione dei dati - di seguito GDPR) e dell'articolo 122 del Codice in materia di protezione dei dati personali (D.Lgs. 196/03, il c.d. Codice privacy) e del Provvedimento generale del Garante dell'8 maggio 2014 in materia di cookie, a coloro che si collegano al sito
    </p>
    <p>
        L'informativa è resa soltanto per il sito sopra menzionato (di seguito “Sito”) e relativi possibili sottodomini, ma non anche per altri siti web eventualmente consultati dall'utente tramite appositi link.
    </p>

    <h3>Titolare del trattamento</h3>
    <p>Il titolare del trattamento è la Sig.ra Elena Colombo (di seguito, “Titolare” o “Tourial”), C.F. CLMLNE92H50F704Q, residente in Lesmo (MB) in Via Casati n. 25 - 20855, contattabile all'indirizzo e-mail: elena.colombo@tourial.it.</p>
    <p>Tourial garantisce il rispetto della normativa in materia di protezione dei dati personali.</p>
    <p>Si invitano gli utenti/visitatori a leggere attentamente la presente Privacy Policy prima di inoltrare qualsiasi tipo di informazione personale e/o compilare qualunque modulo elettronico presente sul sito stesso.</p>

    <h3>Tipologia di dati trattati e finalità del trattamento</h3>

    <h4>1. Dati di navigazione</h4>
    <p>I sistemi informatici e le procedure software preposte al funzionamento di questo Sito acquisiscono, nel normale esercizio, alcuni dati che vengono trasmessi implicitamente nell'uso dei protocolli di comunicazione Internet.</p>
    <p>Si tratta di informazioni che non sono raccolte per essere associate ad interessati identificati, ma che per loro natura potrebbero, anche mediante elaborazioni con dati detenuti da terzi, permettere di identificare gli utenti/visitatori (ad es. indirizzo IP, nomi di domini dei computer utilizzati dagli utenti che si collegano al Sito, il codice indicante lo stato di risposta dati dal server, tipo di browser, indicazioni temporali di inizio e fine della sessione di navigazione, eventuale identificativo dell'account e delle attività svolte nel Sito, ecc.).</p>
    <p>Tali dati così minimizzati e sostanzialmente vengono utilizzati solo per informazioni di tipo statistico e per ottimizzare il funzionamento del Sito. I dati stessi vengono conservati per un periodo non superiore al conseguimento delle finalità per cui sono trattati, fatta salva l'ulteriore conservazione per eventuali accertamenti di reati informatici ai danni del Sito. Nell'ipotesi in cui fosse rilevata una condotta illecita, potrebbe essere necessario identificare l'autore di tale condotta al fine di difendere un diritto in sede giudiziaria, del Titolare o di un terzo (compreso l'interessato), ai sensi dell'art. 6(1)(f) del GDPR ovvero su richiesta dell'Autorità Giudiziaria o delle forze dell'ordine.</p>

    <h4>Dati di contatto</h4>
    <p>E' possibile inviare richieste e comunicazioni attraverso gli indirizzi di contatto riportati sul sito. Il conferimento di tali dati non è obbligatorio, tuttavia è necessario per poter rispondere alle richieste inviate e fornire riscontro, nonché per ricontattare il mittente per ottenere precisazioni in ordine a quanto segnalato. Pertanto, qualora l'utente (interessato) non intendesse acconsentire al trattamento dei propri dati personali, da parte del Titolare, è invitato a non inoltrare alcuna richiesta attraverso i canali di contatto di cui al presente sito o, quantomeno, a fornire il minor numero possibile di dati personali (es. inviando una e-mail non contenente alcun dato personale, nemmeno nella composizione dell’indirizzo di posta elettronica). I dati generalmente raccolti per tale finalità sono i seguenti: nome, cognome, email, nazionalità, città, data di nascita, genere. Il trattamento di tali dati si basa sull'adozione di misure precontrattuali o contrattuali, ai sensi dell'art. 6(1)(b) del GDPR.</p>
    <p>Inoltre, qualora l'interessato (utente) dovesse decidere di iscriversi al servizio, i dati di contatto potranno essere trattati per inviare apposite newsletter a scopo promozionale. Il conferimento di tali dati per tale fine è facoltativo e consente di ricevere informazioni e aggiornamenti sulle novità concernenti il settore dell'arte, turismo e cultura. Il mancato conferimento dei dati per tale finalità comporterà l'impossibilità di ricevere la newsletter. In qualsiasi momento sarà comunque possibile annullare l'iscrizione tramite l'apposito link presente in tutte le e-mail ricevute. I dati raccolti per tale finalità è l'indirizzo email fornito al momento dell'iscrizione ed i . Il trattamento eventuale di tali dati si basa sull'adozione di misure precontrattuali o contrattuali, ai sensi dell'art. 6(1)(b) del GDPR.</p>

    <h4>Dati account utente</h4>
    <p>I dati forniti dall'interessato al momento della creazione di un account (c.d. registrazione), sia esso gratuito (come quelli di prova) o a pagamento, sono necessari per l'erogazione del servizio. Il conferimento di tali dati è necessario per l'erogazione del servizio (si v. termini e condizioni). Qualora non venissero forniti, non sarà possibile erogare il servizio richiesto, nonché adempiere agli obblighi di legge ai quali è soggetto il Titolare, in relazione al servizio erogato (es. obblighi ammnistrativi, contabili, etc.). I dati raccolti per tale finalità sono i seguenti: nome, cognome ed e-mail. Il trattamento di tali dati si basa sull'adozione di misure precontrattuali o contrattuali e per adempiere agli obblighi di legge a cui è soggetto il Titolare, in relazione all'erogazione del servizio (art. 6(1)(b)(c) del GDPR).</p>
    <p>Previo consenso dell'interessato, i dati di contatto potranno essere utilizzati per l'invio di materiale promozionale e comunicazioni commerciali sui prodotti e servizi offerti da parte di Tourial. Il conferimento di tali dati è facoltativo, l'eventuale rifiuto non pregiudicherà il servizio ma comporterà unicamente la mancata ricezione di messaggi contenti offerte e aggiornamenti sui servizi offerti da Tourial. In qualsiasi momento sarà possibile revocare il consenso alla ricezione dei messaggi commerciali tramite l'apposito link presente in tutte le comunicazioni. Il dato raccolto per tale finalità è l'indirizzo email fornito al momento della registrazione. Il trattamento eventuale di tali dati si basa sul rilascio del consenso da parte dell'interessato, ai sensi dell'art. 6(1)(a) del GDPR.</p>
    <p>I sistemi informatici non utilizzano cookies per la trasmissione di informazioni di carattere personale, eccetto quelli di Google Analytics, servizio di analisi web fornito da Google Inc. (“Google”) che utilizza dei cookie che vengono depositati sul computer dell'utente per consentire analisi statistiche in forma aggregata in ordine all'utilizzo del sito web visitato. Tali cookies sono stati da noi impostati in modalità anonimizzata, in modo da ridurre al minimo la possibilità di identificazione da parte di Google o da altre terze parti. I dati generati da Google Analytics sono conservati da Google, così come indicato nella <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">propria informativa sull'utilizzo dei cookies</a>. Come titolare autonomo del trattamento dei dati relativo al servizio di Google Analytics, Google Inc. rende disponibile la <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">propria informativa privacy</a>. Per ulteriori approfondimenti su come gestire o disabilitare i cookie di terze parti o di marketing è possibile visitare il sito <a href="https://www.youronlinechoices.com">www.youronlinechoices.com</a>.</p>

    <h4>Dati sulla posizione geografica</h4>
    <p>Nel rispetto della vigente normativa sulla privacy, dietro previo consenso degli utenti di Tourial, sarà attivato un servizio di geolocalizzazione mediante GPS - Global Positioning System. Le informazioni raccolte con il sistema GPS sono accessibili tramite uno specifico applicativo software, al quale possono accedere unicamente persone esplicitamente incaricate in funzione delle mansioni. Il trattamento dei dati personali, relativi alla posizione dell'interessato, ha lo scopo di facilitare l'utente nell'individuazione dei punti di interesse (POI) intorno a lui. Tourial potrà verificare se la posizione dell'utente si trova nel raggio di 1 Km da un POI presente nel database di Tourial. Il trattamento, eventuale, di tali dati si basa sul rilascio del consenso da parte dell'interessato, ai sensi dell'art. 6(1)(a) del GDPR. L'eventuale rifiuto non comporta alcuna conseguenza, salvo la mancata interazione delle informazioni o dei servizi offerti, in funzione della posizione dell'interessato. </p>

    <h3>Modalità di trattamento</h3>
    <p>Il trattamento viene effettuato attraverso strumenti informatici e telematici e/o manualmente (ad es. su supporto cartaceo) per il tempo strettamente necessario a conseguire gli scopi per i quali i dati sono stati raccolti e comunque, in conformità alle disposizioni normative vigenti in materia.</p>

    <h3>Comunicazione, diffusione e trasferimento dei dati</h3>
    <p>I dati personali non saranno oggetto di diffusione e potranno essere comunicati alle Autorità competenti o ad enti pubblici o privati per l'adempimento di obblighi previsti dalla legge. Inoltre, i dati potranno essere comunicati a società e professionisti di cui il Titolare si avvale al fine di tutelare un proprio diritto ovvero per adempiere ad obblighi di legge (ad esempio, commercialisti, avvocati, consulenti fiscali, consulenti, ecc.).</p>
    <p>I dati personali raccolti potranno essere trattati da fornitori terzi, appositamente nominati quali responsabili esterni del trattamento in relazione ai servizi prestati per conto del Titolare sulla base di appositi accordi contrattuali, eventualmente per occasionali operazioni di manutenzione e per quanto sia necessario per eseguire servizi sotto specifiche richieste. </p>
    <p>Inoltre, nell'ambito dei servizi a pagamento offerti da Tourial (es. account premium), i dati potranno essere comunicati a soggetti terzi che offrono soluzioni sulle modalità di pagamento (es. PayPal, Google Pay, etc.). In tale ipotesi, l'utente verrà reindirizzato automaticamente sulle pagine gestite da parte di tali soggetti, i quali tratteranno i dati degli interessati in qualità di titolari del trattamento relativamente ai servizi offerti.</p>
    <p>I dati non saranno diffusi e non verranno trasferiti al di fuori dell'UE e/o dello Spazio Economico Europeo (SEE).</p>

    <h3>Criteri utilizzati per determinare il tempo di conservazione dei dati</h3>
    <p>I dati dell'utente saranno conservati per il periodo necessario ad adempiere agli obblighi contrattuali e/o di legge.</p>
    <p>Il periodo di conservazione dei dati dipende dalle finalità per cui vengono trattati e pertanto potrebbe variare in ragione di esse. I criteri utilizzati per determinare il periodo di conservazione applicabile sono i seguenti: la conservazione dei dati personali avverrà per il tempo necessario (i) alla gestione del rapporto contrattuale con l'utente, (ii) alla gestione di reclami o specifiche richieste dell'utente, (iii) a far valere diritti in sede giudiziaria nonché (iv) per il tempo previsto da norme di legge applicabili (es. prescrizione in materia di obblighi amministrativi e contabili).</p>
    <p>Qualora venisse promossa una richiesta, i dati di contatto verranno conservati per il solo tempo necessario a poter fornire un riscontro.</p>
    <p>In relazione ai dati di registrazione, gli stessi verranno conservati solo per il tempo in cui verrà mantenuto l'account per l'erogazione dei servizi. Una volta cancellato l'account, saranno conservati i soli dati necessari ad adempiere ad eventuali obblighi di legge (es. obblighi amministrativi e contabili), nei limiti di prescrizione applicabile. </p>
    <p>I dati di geolocalizzazione, relativi al dispositivo utilizzato per accedere ai servizi di Tourial, verranno conservati per il solo tempo di durata della sessione in cui l'interessato ha rilasciato il consenso, allo scopo di fornire indicazione sui POI nelle vicinanze. Una volta disattivata la localizzazione i dati saranno cancellati.</p>
    <p>I dati forniti per registrarsi alla newsletter verranno conservati per il solo tempo di iscrizione al servizio. Qualora l'interessato dovesse decidere di revocare l'iscrizione i dati verranno immediatamente cancellati.</p>
    <p>Qualora l'interessato dovesse rilasciare il consenso alla ricezione di comunicazioni commerciali sui servizi offerti da parte di Tourial (c.d. marketing), il tempo di conservazione dei dati forniti sarà pari a 24 mesi dalla raccolta, salvo non ricorra una revoca del consenso - da parte dell'interessato - prima della scadenza di tale periodo. Al termine dei 24 mesi, i dati personali saranno automaticamente cancellati. In prossimità della scadenza del termine di 24 mesi, sarà richiesto nuovamente all'interessato il consenso per le suddette finalità e in caso affermativo i dati saranno conservati nuovamente per ulteriori 24 mesi, salvo eventuale e successiva revoca.</p>
    <p>Inoltre, per il tempo di conservazione degli eventuali dati personali trattati tramite i cookie si rinvia all'apposita cookie policy.</p>

    <h3>Diritti degli interessati</h3>
    <p>L'utente, in qualità di interessato potrà esercitare, in relazione ai trattamenti dei propri dati personali - sopra descritti - i seguenti diritti, ai sensi degli artt. da 15 a 22 e 77 del GDPR:</p>
    <ul>
        <li>Diritto di accesso: per conoscere quali dati siano trattati e tutte le informazioni relative al trattamento.</li>
        <li>Diritto di rettifica: per poter richiedere la modifica dei dati poiché inesatti o obsoleti.</li>
        <li>Diritto alla portabilità: per ottenere copia dei dati che vengono trattati, in un formato strutturato, di uso comune e leggibile da un dispositivo automatico, al fine di facilitare un eventuale trasferimento dei dati verso un altro titolare del trattamento, diverso da Tourial.</li>
        <li>Diritto di limitazione del trattamento: per poter limitare il trattamento dei propri dati, a condizione che ricorra una delle ipotesi previste dalla disciplina applicabile.</li>
        <li>Diritto alla cancellazione: richiedere la cancellazione dati.</li>
        <li>Diritto di opposizione: richiedere la cessazione del trattamento dei dati.</li>
        <li>Diritto di proporre reclamo all'autorità di controllo: per promuovere eventuali segnalazioni, o reclami al Garante per la protezione dei dati personali.</li>
    </ul>
    <p>Salvo il diritto di proporre reclamo all'Autorità, per cui risulta necessario procedere secondo le modalità indicate sul sito del Garante, le richieste per l'esercizio dei diritti sopra indicati vanno inviate all'indirizzo elena.colombo@tourial.it ovvero tramite la posta ordinaria all'indirizzo del Titolare sopra riportato. Attraverso i medesimi recapiti, l'Utente potrà chiedere inoltre la lista aggiornata di tutti i Responsabili del trattamento nominati dal Titolare.</p>
    <p>La presente informativa (c.d. privacy policy) può essere soggetta ad aggiornamenti, pertanto si invitano gli interessati a verificarne periodicamente il contenuto.</p>

</div>
</template>

<script>
export default {
    name: "privacyPolicy",
    props: {},
    components: {},
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>


<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.privacypolicy-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    height: calc(100% - 75px);
    overflow-x: hidden;
    box-sizing: border-box;
}

.privacypolicy-container.full-page {
    height: 100%;
    padding-bottom: 40px;
    padding-left: 70px;
    padding-right: 20px;
}

p,
h3 {
    margin: 0;
}

h3 {
    margin-top: 20px;
}

/* MOBILE */
@media (max-width: 600px) {
    .top {
        margin-top: 20px;
    }
    .privacypolicy-container {
        padding-top: 20px;
        padding-left: 20px !important;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 90px !important;
        width: 100% !important;
        overflow-x: hidden;
    }

}
</style>
